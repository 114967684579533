import React from 'react'

const Notification = () => (
  <div className="notice">
    <div className="header-inner" id="modal">
      <p className="notification-title">お知らせ</p>
      <p className="notification-txt">
        10月13日（日）、14日（月）は日曜祝日のため、コンシェルジュサポートはお休みとなります。ご理解のほどお願い申し上げます。
      </p>
    </div>
  </div>
)

export default Notification
